const formatVersion = (version: string, includeVersionPrefix = false, removeMinorRelease = false): string => {
  // Extract the MAJOR.MINOR.PATCH version using a regex pattern
  const matches = version.match(/v?(\d+\.\d+\.\d+)/)

  if (!matches) {
    return ""
  }

  let result = `${matches[1]}`

  if (removeMinorRelease) {
    // Split the version into MAJOR, MINOR, and PATCH components
    const parts = result.split(".")
    // Remove the PATCH component
    parts.pop()
    // Reconstruct the version string
    result = parts.join(".")
  }

  if (includeVersionPrefix) {
    return `v${result}`
  }

  return result
}

export default formatVersion
