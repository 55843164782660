import NextHead from "next/head"

export type Head = {
  title: string
  description?: string
  noIndex?: boolean
}

const Head = (props: Head) => {
  const { title, description, noIndex = false } = props

  return (
    <NextHead>
      {title && <title>{`${title} | Parallel Domain`}</title>}
      {description && <meta name="description" content={description} />}
      {noIndex && <meta name="robots" content="noindex" />}
    </NextHead>
  )
}

export default Head
